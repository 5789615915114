import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CoinLaunchDate from "./utils/coinLaunchDate";
import { StyledTableData, StyledTableRowHeaders } from "./coinSumary.styles";

const getCurrentEnvironment = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes("localhost") || currentUrl.includes("personal")) {
    return "-personal";
  }

  if (currentUrl.includes("dev")) {
    return "-dev";
  }
  return "";
};

const getAllCoins = async () => {
  const apiGatewayUrl = `https://api${getCurrentEnvironment()}.rugmenot.net`;
  try {
    const response = await fetch(`${apiGatewayUrl}/coins`, {
      headers: {
        "Cache-Control": "max-age=0",
        "content": "application/json"
      }
    });
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export default function CoinSummary() {
  useQueryClient();
  const { isPending, isError, data, error } = useQuery({
    queryKey: "allCoins",
    queryFn: async () => await getAllCoins(),
  });

  console.log("ispending", isPending);
  console.log("isError", isError);

  if (isPending) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const cleanUpCoinNames = (word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord + " Coin";
  };

  return (
    <div id="coinTable" className="container">
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <div className="section-title">
              <h2>Current Coins</h2>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableRowHeaders align="left">
                        Coin Name
                      </StyledTableRowHeaders>
                      <StyledTableRowHeaders align="left">
                        Launch Date
                      </StyledTableRowHeaders>
                      <StyledTableRowHeaders align="left">
                        Coin Site
                      </StyledTableRowHeaders>
                      <StyledTableRowHeaders align="left">
                        Pump
                      </StyledTableRowHeaders>
                      <StyledTableRowHeaders align="left">
                        CA
                      </StyledTableRowHeaders>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((coin) => (
                      <TableRow
                        key={coin.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableData component="th" scope="row">
                          {cleanUpCoinNames(coin.name)}
                        </StyledTableData>
                        <StyledTableData align="left">
                          <CoinLaunchDate
                            epochTime={coin.data.launchEpochDate}
                          />
                        </StyledTableData>
                        <StyledTableData>
                          {" "}
                          <a
                            href={coin.data.internalSite}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {coin.data.internalSite}
                          </a>
                        </StyledTableData>
                        <StyledTableData align="left">
                          <a
                            href={coin.data.pumpFunUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            pump.fun
                          </a>
                        </StyledTableData>
                        <StyledTableData align="left">
                          {coin.data.ca}
                        </StyledTableData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
