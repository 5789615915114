import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: `"Raleway", "sans-serif"`,

  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        font-family: "Raleway", sans-serif;
        font-size: large;
      `,
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: 30
      }
    }
  },
});

