import * as React from "react";
import Countdown from "react-countdown";
import {compareAsc, getTime, format} from "date-fns";

export default function CoinLaunchDate(epochTime) {
  let highlightSeconds;
  const convertToHumanReadableDate = (epochTime) => {
    if (epochTime === 0) {
      return `Launch date not chosen`;
    }
    return format(new Date(epochTime), 'MM-dd-yyyy');
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    highlightSeconds = () => {
      const secondsElement = document.querySelector('.seconds'); // Target the seconds <span>
      if (secondsElement) {
        secondsElement.classList.add('rerendered');
        setTimeout(() => {
          secondsElement.classList.remove('rerendered');
        }, 500);
      }
    }

    return (
        <span className="countdown">  {/* Added a class for easier targeting */}
          {hours > 0 ? `${hours}:` : ''}{minutes > 0 || hours > 0 ? `${minutes}:` : ''}
          <span className='seconds'>{seconds}</span>
        </span>
    );
  };

  const onTickHandler = () => {
    highlightSeconds();
  }

  const dateHandler = (epochTime) => {
    const currentTime = getTime(new Date());
    if (compareAsc(epochTime.epochTime, currentTime) > 0) {
      return <Countdown date={epochTime.epochTime} renderer={renderer} onTick={onTickHandler} intervalDelay={1000}/>;
    } else {
      return convertToHumanReadableDate(epochTime.epochTime);
    }
  };

  return <div>{dateHandler(epochTime)}</div>;
}
