import React, {useEffect, useState} from "react";
import {Navigation} from "./components/navigation";
import {Header} from "./components/header";
import {About} from "./components/about";
import {Services} from "./components/services";
import {Gallery} from "./components/gallery";
import {Testimonials} from "./components/testimonials";
import {Contact} from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import CoinSummary from "./components/coinSummary";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ThemeProvider} from "@mui/material";
import {theme} from './theme'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Navigation />
        <Header data={landingPageData.Header} />
        <Gallery data={landingPageData.Gallery} />
        <CoinSummary />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Testimonials data={landingPageData.Testimonials} />
        <Contact data={landingPageData.Contact} />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
