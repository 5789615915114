import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";

export const StyledTableRowHeaders = styled(TableCell)({
  fontSize: "20px",
  fontWeight: 600,
});

export const StyledTableData = styled(TableCell)({
  fontSize: "16px",
  fontWeight: 400,
  'a': {
    fontWeight: 600
  }
});
