import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  "faTwitter": faTwitter,
  "faTelegram": faTelegram,
  "faEnvelope": faEnvelope
}

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Social Contacts</h2>
          <p>
            If you're bored, look at our stuff or yell at us.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 social">
                  {" "}
                  <a href={d.link} ><FontAwesomeIcon icon={iconMap[d.icon]} class="social-contact-link"/></a>
                  <div className="service-desc ">
                    <h3>{d.name}</h3>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
